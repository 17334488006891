export const FIND_PRODUCT_BY_ID_REQUEST = "FIND_PRODUCT_BY_ID_REQUEST";
export const FIND_PRODUCT_BY_ID_SUCCESS = "FIND_PRODUCT_BY_ID_SUCCESS";
export const FIND_PRODUCT_BY_ID_FAILURE = "FIND_PRODUCT_BY_ID_FAILURE";

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const GET_FEATURED_PRODUCTS_REQUEST = "GET_FEATURED_PRODUCTS_REQUEST";
export const GET_FEATURED_PRODUCTS_SUCCESS = "GET_FEATURED_PRODUCTS_SUCCESS";
export const GET_FEATURED_PRODUCTS_FAILURE = "GET_FEATURED_PRODUCTS_FAILURE";
