export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";

export const FIND_ORDER_BY_ID_REQUEST = "FIND_ORDER_BY_ID_REQUEST";
export const FIND_ORDER_BY_ID_SUCCESS = "FIND_ORDER_BY_ID_SUCCESS";
export const FIND_ORDER_BY_ID_FAILURE = "FIND_ORDER_BY_ID_FAILURE";

export const GET_USER_ORDER_HISTORY_REQUEST = "GET_USER_ORDER_HISTORY_REQUEST";
export const GET_USER_ORDER_HISTORY_SUCCESS = "GET_USER_ORDER_HISTORY_SUCCESS";
export const GET_USER_ORDER_HISTORY_FAILURE = "GET_USER_ORDER_HISTORY_FAILURE";