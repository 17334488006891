export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";

export const GET_NON_FEATURED_PRODUCTS_REQUEST =
  "GET_NON_FEATURED_PRODUCTS_REQUEST";
export const GET_NON_FEATURED_PRODUCTS_SUCCESS =
  "GET_NON_FEATURED_PRODUCTS_SUCCESS";
export const GET_NON_FEATURED_PRODUCTS_FAILURE =
  "GET_NON_FEATURED_PRODUCTS_FAILURE";

export const DELETE_PRODUCT_BY_ID_REQUEST = "DELETE_PRODUCT_BY_ID_REQUEST";
export const DELETE_PRODUCT_BY_ID_SUCCESS = "DELETE_PRODUCT_BY_ID_SUCCESS";
export const DELETE_PRODUCT_BY_ID_FAILURE = "DELETE_PRODUCT_BY_ID_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const ADD_PRODUCTS_TO_FEATURED_REQUEST =
  "ADD_PRODUCTS_TO_FEATURED_REQUEST";
export const ADD_PRODUCTS_TO_FEATURED_SUCCESS =
  "ADD_PRODUCTS_TO_FEATURED_SUCCESS";
export const ADD_PRODUCTS_TO_FEATURED_FAILURE =
  "ADD_PRODUCTS_TO_FEATURED_FAILURE";

export const REMOVE_PRODUCT_FROM_FEATURED_REQUEST =
  "REMOVE_PRODUCT_FROM_FEATURED_REQUEST";
export const REMOVE_PRODUCT_FROM_FEATURED_SUCCESS =
  "REMOVE_PRODUCT_FROM_FEATURED_SUCCESS";
export const REMOVE_PRODUCT_FROM_FEATURED_FAILURE =
  "REMOVE_PRODUCT_FROM_FEATURED_FAILURE";

export const RESET_UPDATED_PRODUCT = "RESET_UPDATED_PRODUCT";
