import React from 'react'

const CustomerList = () => {
  return (
    <div>
      CustomerList
    </div>
  )
}

export default CustomerList
