export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const CONFIRM_ORDER_REQUEST = "CONFIRM_ORDER_REQUEST";
export const CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS";
export const CONFIRM_ORDER_FAILURE = "CONFIRM_ORDER_FAILURE";

export const SHIP_ORDER_REQUEST = "SHIP_ORDER_REQUEST";
export const SHIP_ORDER_SUCCESS = "SHIP_ORDER_SUCCESS";
export const SHIP_ORDER_FAILURE = "SHIP_ORDER_FAILURE";

export const DELIVER_ORDER_REQUEST = "DELIVER_ORDER_REQUEST";
export const DELIVER_ORDER_SUCCESS = "DELIVER_ORDER_SUCCESS";
export const DELIVER_ORDER_FAILURE = "DELIVER_ORDER_FAILURE";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";

