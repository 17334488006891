export const mainCarouselData = [
  {
    image: "/images/mainCarousel/carImage1.png",
  },
  {
    image: "/images/mainCarousel/carImage2.png",
  },
  {
    image: "/images/mainCarousel/carImage3.png",
  },
];
